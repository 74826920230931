import * as React from 'react';
import withWSMessageReceiver, {
  WSMessageReceiverComponent,
} from '../../../utils/withWSMessageReceiver';
import Admin from '../admin';
import { RoomAdminComponentProps } from '../roomAdmin';

const RotiAdmin: WSMessageReceiverComponent<RoomAdminComponentProps> = (
  { registrationData, sendWS, participants },
  ref,
) => {
  return (
    <Admin
      ref={ref}
      registrationData={registrationData}
      sendWS={sendWS}
      participants={participants}
    />
  );
};

RotiAdmin.displayName = 'RotiAdmin';

export default withWSMessageReceiver(RotiAdmin);
