import * as React from 'react';
import { VoidPageProps } from '../../../utils/graphql';
import RotiAdmin from '../../../components/tools/roti/rotiAdmin';
import Animate from '../../../components/tools/animate';

const ConfidenceVoteAnimate: React.VoidFunctionComponent<VoidPageProps> = ({ pageContext }) => {
  return <Animate pageContext={pageContext} toolId="roti" RoomComponent={RotiAdmin} />;
};

export default ConfidenceVoteAnimate;
